var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"95cc9523c7e86a23211d9abf63a34b67ecdb6d16"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/_assets";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT =
  process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

const isDevelopment = ENVIRONMENT === 'development';

const shipmentNotFoundRegex = /Shipment .*not found/;
const externalShipmentNotFoundRegex = /External shipment .*not found/;

Sentry.init({
  environment: ENVIRONMENT,
  enabled: !isDevelopment,
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'Network Error',
    'Failed to fetch',
    'A network error occurred',
    'NetworkError when attempting to fetch resource.',
    'Load failed',
    'Request aborted',
    'cancelled',
    'network error (Error)',
    'Non-Error promise rejection captured',
  ],
  allowUrls: [
    /https?:\/\/(.*)?packfleet\.com/,
    /https?:\/\/(.*)?-packfleet\.vercel\.app/,
  ],
  beforeSend(event, _hint) {
    // Suppress Packfleet specified errors from being sent to Sentry to avoid unnecessary
    // Sentry events
    if (
      event.transaction === 'ReadTrackingInfo' &&
      event.exception?.values?.find((v) => shipmentNotFoundRegex.test(v.value))
    ) {
      return null;
    }

    if (
      event.transaction === 'ReadExternalTrackingInfo' &&
      event.exception?.values?.find(
        (v) =>
          shipmentNotFoundRegex.test(v.value) ||
          externalShipmentNotFoundRegex.test(v.value),
      )
    ) {
      return null;
    }

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
